import type { FC } from "react";
import React, { useCallback } from "react";

import { questionnaireFieldValidation } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import Loader from "src/ui/components/Loader/Loader";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";

import {
  AutoForm,
  AutoFormDataField,
  AutoFormDataFieldDate,
  Button,
  DataFieldGroup,
  pickerCurrentTime,
  useAutoFormControls
} from "@9amhealth/shared";
import {
  getLocalTimeZone,
  now as internationalizedNow
} from "@internationalized/date";
import { Blac } from "blac-next";
import { IconBloodPressureHeartWithClock } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { validateDate } from "src/lib/zod/validateDate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import { handleOpenTimePicker } from "src/ui/components/TimePickerSheet/TimePickerSheet";
import Track from "src/ui/components/Track/Track";

const BloodPressureSchema = z.object({
  date: validateDate,
  bp: z.string().refine(
    (v) => {
      if (!v) return false;
      const bpRegex = questionnaireFieldValidation.bloodpressure;
      return bpRegex.test(v);
    },
    { message: translate("upload_labs.blood_pressure.invalid_input_error") }
  )
});

type FormValues = z.infer<typeof BloodPressureSchema>;

const BloodPressureForm: FC<{
  onComplete: () => void;
}> = (props) => {
  const [errorMessage, setErrorMessage] = React.useState<TranslationKey>();

  const initialFormValues: FormValues = {
    bp: "",
    date: internationalizedNow(getLocalTimeZone())
  };

  const handleSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      let error;

      const [dateTimeZone] = formValues.date.toString().split("[");
      try {
        const observation = LabResultsCubit.createObservationBloodPressure(
          formValues.bp,
          dateTimeZone
        );

        error = await LabResultsCubit.storeSelfReportedLabValues(
          [observation],
          "messenger"
        );

        tracker.track(TrackEvent.BloodPressureForm, {
          type: TrackType.complete
        });
        void Blac.getBloc(LabResultsCubit).loadObservations();
      } catch (observationError: unknown) {
        reportErrorSentry(observationError);
        error = (observationError as Error).message;
      }

      if (error) {
        setErrorMessage("error_generic");
      } else {
        props.onComplete();
      }
    },
    [props.onComplete]
  );

  const autoFormControls = useAutoFormControls({
    schema: BloodPressureSchema,
    initialValue: initialFormValues,
    onSubmit: (data) => void handleSubmit(data as unknown as FormValues)
  });

  const handleFocusChange = () => {
    const isCoarse = matchMedia("(pointer:coarse)").matches;

    if (!isCoarse) {
      return;
    }

    handleOpenTimePicker({
      onFinish: (dateTime) => {
        autoFormControls.setValue("date", dateTime);
      },
      initialDate: autoFormControls.getValues().date,
      title: translate("upload_labs.blood_pressure.date_input_label"),
      granularity: "minute"
    });
  };

  return (
    <div>
      <h3 className="as-h4 center">
        <div style={{ opacity: 0.2 }}>
          <IconBloodPressureHeartWithClock />
        </div>
        <Translate msg="upload_labs.blood_pressure.title" />
      </h3>

      <nine-spacer s="md"></nine-spacer>

      <Track event={TrackEvent.BloodPressureForm} type={TrackType.open} />

      <nine-spacer s="md"></nine-spacer>

      <AutoForm {...autoFormControls.props}>
        <DataFieldGroup>
          <AutoFormDataFieldDate
            onFocus={handleFocusChange}
            maxValue={pickerCurrentTime()}
            label={translate("upload_labs.blood_pressure.date_input_label")}
            name="date"
            granularity="minute"
            hideTimeZone
          />

          <AutoFormDataField
            name="bp"
            mask="00[0]/00[0]"
            label={translate("upload_labs.blood_pressure.value_input_label")}
            affix="mmHg"
          />
        </DataFieldGroup>
        <span className="color-c-60 as-little ml1">
          {translate("upload_labs.blood_pressure.example")}
        </span>

        <nine-spacer s="lg" />

        <Button
          type="submit"
          theme="charcoal"
          style={{ color: "white", margin: "auto" }}
        >
          {translate("confirm")}
        </Button>
      </AutoForm>

      {errorMessage && (
        <>
          <nine-spacer s="sm"></nine-spacer>
          <ErrorBox data-severity="error">
            <Translate msg={errorMessage} fallback="error_generic" />
          </ErrorBox>
        </>
      )}

      <Loader fixed absolute background overContent />
    </div>
  );
};

export default BloodPressureForm;
